import { Box, Center, Image, Text, Tooltip } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'

import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import {
  getColorModeValue,
  getConfigLng,
  handleS3SourceDomain,
} from '@/utils/tools'

import type { AnnounceContentProps } from '../auto-modal/ModalInterface'
import { useModalConfig } from '../auto-modal/useModal'
import { handleSliceText } from '../auto-modal/utils'
import { GlobalModalKey } from '../global-modal/types'

const ClientHeight = document.documentElement.clientHeight

const lng = getConfigLng()

function AnnounceCon(props: {
  info: AnnounceContentProps
  content: AnnounceContentProps[]
}) {
  const { info, content } = props
  const [isArrowVisible, setIsArrowVisible] = useState(false)
  const [loadedImageNumber, setLoadedImageNumber] = useState(0)
  const scrollbarRef = useRef<Scrollbars | null>(null)
  const curModalInfo = (info || {}) as AnnounceContentProps
  const { language_content = {} } = curModalInfo
  const data = language_content[lng] || {}
  const { onCloseModal } = useModalConfig()
  const actionTrigger = useActionTrigger()

  const onClose = () => {
    // 图片或者按钮的点击回调，产品要求，中断弹窗队列，不再继续后面的弹窗
    onCloseModal(GlobalModalKey.Announce, true)
  }

  /**
   * 处理\n不换行问题
   * @param content
   */
  const hanldeContent = (content: string) => {
    content = content || ''
    content = content.replace(/\n/g, '<br>')
    return content
  }

  const singleConHeight = ClientHeight < 750 ? '400px' : '450px'
  const multiConHeight = ClientHeight < 750 ? '360px' : '410px'

  useEffect(() => {
    if (scrollbarRef.current) {
      const ch = scrollbarRef.current.getClientHeight()
      const sh = scrollbarRef.current.getScrollHeight()
      setIsArrowVisible(sh > ch)
    }
  }, [data.content, loadedImageNumber])

  return (
    <Box
      bg='announceDialog.modalConBg'
      borderRadius={'18px'}
      padding={'6px'}
      width={'100%'}
      textAlign={'left'}
      position={'relative'}
      marginBottom={content?.length <= 1 ? '50px' : '12px'}
      marginTop={'-26px'}
    >
      <Box
        width='100%'
        height={content?.length <= 1 ? singleConHeight : multiConHeight}
        display={'flex'}
        flexDirection={'column'}
        border={'1px solid rgba(0,0,0,0.06)'}
        padding={'16px 0px 24px 18px'}
        borderColor={'announceDialog.borderLineColor'}
        borderRadius={'18px'}
        position={'relative'}
      >
        <Box
          width='100%'
          as={Scrollbars}
          onScroll={e => {
            const scrollbar = scrollbarRef.current
            if (scrollbar) {
              const isTouchBottom =
                scrollbar.getClientHeight() + scrollbar.getScrollTop() >=
                scrollbar.getScrollHeight() - 10
              if (isTouchBottom && isArrowVisible) {
                setIsArrowVisible(false)
              } else if (!isTouchBottom && !isArrowVisible) {
                setIsArrowVisible(true)
              }
            }
          }}
          ref={scrollbarRef}
          flex={1}
          autoHide
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '4px',
                backgroundColor: 'var(--chakra-colors-gray-600)',
                borderRadius: 'inherit',
              }}
            />
          )}
        >
          <Box pr='5'>
            <Text
              marginBottom={'14px'}
              color='announceDialog.modalTitle'
              fontSize={'18px'}
              fontWeight={800}
              textAlign={'center'}
            >
              {data?.title || ''}
            </Text>
            {data?.imgs?.map((item, index) => {
              if (!item.url) return null

              return (
                <Box
                  key={index}
                  width='100%'
                  as='button'
                  marginBottom='14px'
                  onClick={actionTrigger(item?.img_action, onClose)}
                >
                  <Image
                    src={handleS3SourceDomain(item.url || '')}
                    maxWidth='100%'
                    height='auto'
                    margin='auto'
                    onLoad={() => setLoadedImageNumber(prev => prev + 1)}
                  />
                </Box>
              )
            })}
            <Text
              color='announceDialog.modalContent'
              fontSize={'12px'}
              fontWeight={600}
              marginBottom={'12px'}
              width='100%'
              dangerouslySetInnerHTML={{
                __html: hanldeContent(data?.content || ''),
              }}
            />
          </Box>
        </Box>
        {isArrowVisible && (
          <Center
            width='100%'
            height='20px'
            position='absolute'
            bottom='2'
            left={0}
            color={getColorModeValue('prim.500', 'white')}
          >
            <motion.svg
              initial={{ y: 0 }}
              animate={{ y: '20%' }}
              transition={{
                repeat: Infinity,
                duration: 0.8,
                repeatType: 'reverse',
                type: 'tween',
              }}
              width='24'
              height='24'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                opacity='0.5'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.19351 6.42829C5.53359 5.9752 6.20484 5.8639 6.69279 6.17969L12.23 9.76333L17.2713 6.20405C17.7448 5.86978 18.4204 5.9552 18.7804 6.39484C19.1404 6.83448 19.0484 7.46185 18.5749 7.79612L12.9094 11.7961C12.5373 12.0588 12.0253 12.0687 11.6418 11.8205L5.46124 7.82049C4.97329 7.50469 4.85343 6.88139 5.19351 6.42829Z'
                fill='currentColor'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.19351 12.4283C5.53359 11.9752 6.20484 11.8639 6.69279 12.1797L12.23 15.7633L17.2713 12.2041C17.7448 11.8698 18.4204 11.9552 18.7804 12.3948C19.1404 12.8345 19.0484 13.4619 18.5749 13.7961L12.9094 17.7961C12.5373 18.0588 12.0253 18.0687 11.6418 17.8205L5.46124 13.8205C4.97329 13.5047 4.85343 12.8814 5.19351 12.4283Z'
                fill='currentColor'
              />
            </motion.svg>
          </Center>
        )}
        {data?.btn_text && (
          <Box height='46px' margin={'auto'} marginTop={'20px'}>
            <Box
              as='button'
              minWidth={'40%'}
              margin={'auto'}
              height='46px'
              padding='0px 20px'
              bg='announceDialog.ModalBtnBg'
              color='announceDialog.modalBtnColor'
              onClick={actionTrigger(data?.btn_action, onClose)}
              cursor={'pointer'}
              borderRadius={'10px'}
            >
              <Tooltip label={data?.btn_text || ''} fontSize='sm'>
                <Text height='46px' lineHeight='46px' cursor={'pointer'}>
                  {handleSliceText(data?.btn_text || '', 20)}
                </Text>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default AnnounceCon
