import { Box, useOutsideClick } from '@chakra-ui/react'
import React, { memo, useRef } from 'react'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'

import {
  closeDrawerStart,
  createDrawerOpenSelector,
  DrawerKey,
  drawerTransitionEnd,
} from './app-layout-slice'
import { HEADER_HEIGHT, RIGHT_DRAWER_WIDTH } from './constants'

interface RightDrawerProps {
  children?: React.ReactNode
}

function _RightDrawer(props: RightDrawerProps) {
  const { children } = props
  const dispatch = useAppDispatch()
  const rightDrawerContainerRef = useRef<HTMLDivElement>(null)

  const isRightDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Right),
  )

  const handleLeftDrawerTranslationEnd = () => {
    dispatch(drawerTransitionEnd(DrawerKey.Right))
  }

  useOutsideClick({
    ref: rightDrawerContainerRef,
    handler: () => dispatch(closeDrawerStart(DrawerKey.Right)),
    enabled: isRightDrawerOpen,
  })

  const drawerStyles: React.ComponentProps<typeof Box> = {
    position: ['fixed', 'sticky'],
    paddingTop: ['0', HEADER_HEIGHT],
    overflow: 'hidden',
    top: '0',
    width: ['full', 'auto'],
    left: ['full', 'auto'],
    zIndex: ['modal', 'sticky'],
  }

  const drawerBaseStyles: React.ComponentProps<typeof Box> = {
    marginTop: `-${HEADER_HEIGHT}`,
  }

  return (
    <Box {...drawerBaseStyles} ref={rightDrawerContainerRef}>
      <Box
        {...drawerStyles}
        transform={[
          isRightDrawerOpen
            ? 'translate3d(-100%,0px,0px)'
            : 'translate3d(0px,0px,0px)',
          'none',
        ]}
        transition={['transform 0.3s', 'none']}
        onTransitionEnd={handleLeftDrawerTranslationEnd}
      >
        <Box
          height={['100vh', `calc(100vh - ${HEADER_HEIGHT})`]}
          overflow='hidden'
        >
          <Box width={['full', RIGHT_DRAWER_WIDTH]} height='full'>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const RightDrawer = memo(_RightDrawer)

export default RightDrawer
