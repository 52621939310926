import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { useGetWithdrawBindPhoneSwitchMutation } from '@/modules/bind-phone/bindPhoneApi'
import {
  getIsBindPhone,
  openBindPhoneModal,
} from '@/modules/bind-phone/bindPhoneSlice'
import { selecVipConfigList } from '@/modules/new-vip/newVipSlice'
import { selectVipLevel } from '@/modules/user/userSlice'
import { useWithdrawMutation } from '@/modules/wallet/walletApi'
import {
  CODE_MAP,
  delay,
  MAX_RETYR_COUNT,
  rechargeErrorCode,
} from '@/modules/wallet/walletContansts'
import {
  getQuestType,
  getUserAssets,
  getWithdrawalCurrency,
  getWithdrawFeilds,
  getWithdrawWays,
  selectWithdrawAmount,
  setHandleType,
  setQuestType,
  setSaveInfo,
  setShowFullPageLoading,
  setWithdrawParamValue,
} from '@/modules/wallet/walletSlice'
import { isQueryError } from '@/services/types'
import { useUserAuth } from '@/utils/hooks/useUserAuth'
import { useWalletConfig } from '@/utils/hooks/useWalletConfig'
import { getFeatures } from '@/utils/tools'

import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import useAccountsInfo from '../new-login/hooks/useAccountsInfo'
import { LoginVerifyCodeType } from '../new-login/loginApi'
import { useSaveList } from './useSavaList'
import { handlePIXSubmitInfo } from './utils'

const RECHARGE_ERROR_TIPS = getVariable('RECHARGE_ERROR_TIPS')
const loginAndSignUpV3 = getFeatures('loginAndSignUpV3')
const withdraw_error_tips = isFeatureEnabled('withdraw_error_tips')

export const useWithdraw = () => {
  const { bindInfoValue } = useAccountsInfo()
  const isBindPhone = useAppSelector(getIsBindPhone)
  const [isbBindPhoneQuery] = useGetWithdrawBindPhoneSwitchMutation()
  const CURRENCY = getCurrencyCode()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const questType = useAppSelector(getQuestType)
  const { t } = useTranslation()
  const [userId, token] = useUserAuth()
  const withdrawAmount = useAppSelector(selectWithdrawAmount)
  const withdrawalCurrency = useAppSelector(getWithdrawalCurrency) || CURRENCY
  const userAssets = useAppSelector(getUserAssets)[withdrawalCurrency] ?? {
    withdrawable_amount: 0,
  }
  const [withdraw, result] = useWithdrawMutation()
  const { MAX_WITHDRAW_AMOUNT, MIN_WITHDRAW_AMOUNT } =
    useWalletConfig(withdrawalCurrency)

  const withdrawFields = useAppSelector(getWithdrawFeilds)
  const withdrawWays = useAppSelector(getWithdrawWays)

  const { getInfoById } = useSaveList()

  const { isLoading, error: withdrawError } = result
  const [errorCount, setErrorCount] = useState(1)
  const [errorParams, setErrorParams] = useState({})

  const vipList = useAppSelector(selecVipConfigList)
  const uerVipLevel = useAppSelector(selectVipLevel)

  const trigger = useCallback(
    async (data: Record<string, string>, cb?: () => any) => {
      if (isLoading) return

      let min_withdraw =
        MIN_WITHDRAW_AMOUNT[data?.pay_method] || MIN_WITHDRAW_AMOUNT['DEFAULT']

      let max_withdraw =
        MAX_WITHDRAW_AMOUNT[data?.pay_method] || MAX_WITHDRAW_AMOUNT['DEFAULT']

      const curVipConfig = vipList.find(item => item.level === uerVipLevel)
      const vipMinWithdraw = Number(
        curVipConfig?.config?.minimum_withdraw_limit || 0,
      )

      min_withdraw = Math.max(vipMinWithdraw, min_withdraw)

      if (withdrawAmount < min_withdraw) {
        toast({
          title: t('WIHDRAW_AMOUNT_LESS_TIPS', { number: min_withdraw }),
          status: 'error',
        })
        return
      }

      const vipMaxWithdraw = Number(
        curVipConfig?.config?.maximum_withdraw_limit || 0,
      )

      max_withdraw = Math.min(vipMaxWithdraw, max_withdraw)

      if (withdrawAmount > max_withdraw) {
        toast({
          title: t('WIHDRAW_AMOUNT_MAXIMUM_TIPS', { number: max_withdraw }),
          status: 'error',
        })
        return
      }
      if (withdrawAmount > (userAssets.withdrawable_amount || 0)) {
        const amount = userAssets?.amount || 0
        const withdrawable_amount = userAssets?.withdrawable_amount || 0
        // 是否有未完成的打码量
        const isHasbetTarget = amount - withdrawable_amount > 0
        if (isFeatureEnabled('walletGiftTips') && isHasbetTarget) {
          dispatch(openModal({ key: GlobalModalKey.WalletGiftTips }))
        } else {
          /** 如果存在打码任务， 则弹窗提示 */
          toast({
            title: t(CODE_MAP['1018']),
            status: 'error',
          })
        }
        return
      }
      setErrorParams(data)
      try {
        // 提现之前需要判断后台是不是开启了绑定手机号 && 用户是否已经绑定了手机号

        if (
          (loginAndSignUpV3 && !bindInfoValue?.phone?.verified) ||
          !isBindPhone
        ) {
          // 未绑定手机号，需要判断后台是否开启了需要绑定手机号；已经绑定的，就不需要判断后台开关了
          const { data } = await isbBindPhoneQuery(null)
          if (data?.data?.enable === 1) {
            // 需要绑定手机号
            cb && cb()

            if (loginAndSignUpV3) {
              let verifyType = undefined

              if (!bindInfoValue?.phone?.value) {
                verifyType = LoginVerifyCodeType.uvctBindingPhone
              }
              dispatch(
                openModal({
                  key: GlobalModalKey.BindInfoModal,
                  data: {
                    type: 'phone',
                    verifyType,
                    source: 'withdraw',
                  },
                }),
              )
              return
            }
            dispatch(openBindPhoneModal(false))
            return
          }
        }

        if (!isBindPhone) {
          // 未绑定手机号，需要判断后台是否开启了需要绑定手机号；已经绑定的，就不需要判断后台开关了
          const { data } = await isbBindPhoneQuery(null)
          if (data?.data?.enable === 1) {
            // 需要绑定手机号
            cb && cb()
            dispatch(openBindPhoneModal(false))
            return
          }
        }
        await withdraw({
          user_id: userId,
          token,
          amount: withdrawAmount,
          currency: withdrawalCurrency,
          data: handlePIXSubmitInfo(data),
        }).unwrap()
        toast({
          title: t('WITHDRAW_SUCCESS_TITLE'),
          status: 'success',
        })
      } catch (error) {
        if (isQueryError(error)) {
          dispatch(setQuestType('retry'))
        }
      }
    },
    [
      isBindPhone,
      uerVipLevel,
      isLoading,
      withdrawAmount,
      withdraw,
      userId,
      token,
      toast,
      t,
      withdrawalCurrency,
      userAssets.withdrawable_amount,
      bindInfoValue,
    ],
  )
  const _error_retry = useCallback(async () => {
    dispatch(setQuestType('request'))
    if (questType === 'request' && errorCount >= MAX_RETYR_COUNT) {
      setErrorCount(1)
      return
    }
    if (
      withdrawError &&
      errorCount < MAX_RETYR_COUNT &&
      questType === 'retry'
    ) {
      dispatch(
        setShowFullPageLoading({
          loading: true,
          text: 'WAIT_WITHDRAWAL',
        }),
      )
      await delay()
      trigger(errorParams)
      setErrorCount(pre => pre + 1)
    }
    if (errorCount >= MAX_RETYR_COUNT && !isLoading) {
      dispatch(
        setShowFullPageLoading({
          loading: false,
          text: '',
        }),
      )
      // 只有B系列有下面的提示
      if (
        (RECHARGE_ERROR_TIPS || withdraw_error_tips) &&
        rechargeErrorCode?.[withdrawError?.status]
      ) {
        toast({
          status: 'error',
          title: t(rechargeErrorCode?.[withdrawError?.status] || ''),
        })

        dispatch(setHandleType('update'))
        // dispatch(openParamsModal())
        const info = (getInfoById(errorParams?.pay_method_id || '') ||
          {}) as Record<string, any>
        const result: { [key: string]: string } = {}
        const fields = withdrawFields?.[withdrawWays] || []
        fields?.forEach(item => {
          if (info?.[item]) {
            result[item] = info?.[item]
          }
        })

        dispatch(setWithdrawParamValue(result)) //编辑
        dispatch(setSaveInfo({ use: true, id: info?.id || '', item: info }))
        // 需要打开信息弹窗
      } else {
        const text =
          t(CODE_MAP[withdrawError?.status]) || t('NETWORK_EXCEPTION')
        toast({ status: 'error', title: t(text), duration: 5000 })
      }
      await delay(100)
      setErrorCount(1)
    }
  }, [
    withdrawError,
    errorCount,
    toast,
    trigger,
    t,
    isLoading,
    errorParams,
    dispatch,
    questType,
    withdrawFields,
    withdrawWays,
  ])

  useEffect(() => {
    if (questType === 'retry') _error_retry()
  }, [questType, errorCount])
  return { trigger, result }
}
