import type { IconProps, TooltipProps } from '@chakra-ui/react'
import { Box, Icon, IconButton, Tooltip } from '@chakra-ui/react'
import type { JSXElementConstructor, ReactElement, ReactNode } from 'react'
import { useState } from 'react'

import { Icon as SharedIcon } from '@/utils/atom-shared'

interface InfoTooltipProps {
  iconNode?: ReactElement<any, string | JSXElementConstructor<any>>
  iconProps?: IconProps
  tooltipProps: Omit<TooltipProps, 'children'>
}

export function InfoTooltip(props: InfoTooltipProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { tooltipProps, iconProps, iconNode } = props
  return (
    <Tooltip
      hasArrow
      size='lg'
      colorScheme='white'
      {...tooltipProps}
      pointerEvents={'all'}
      isOpen={isOpen}
    >
      <IconButton
        onFocus={e => e.preventDefault()}
        onBlur={() => setIsOpen(false)}
        aria-label='info'
        variant='unstyledIcon'
        verticalAlign='middle'
        onClick={() => setIsOpen(!isOpen)}
        icon={
          iconNode ? (
            iconNode
          ) : (
            <Icon as={SharedIcon.Question} boxSize='4' {...iconProps} />
          )
        }
      />
    </Tooltip>
  )
}
